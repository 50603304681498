import { MF } from 'mass-tools';

import Exercise from './Exercise';

export default function StateBuilder(state) {
  this.state = state;
  this.exercises = state.exercises;

  this.initExercises = () => {
    const {
      charge = { exercises: [] },
      monoisotopic = { exercises: [] },
      determineMF = { exercises: [] },
      isotopicDistribution = { exercises: [] },
    } = this.exercises;

    charge.exercises = charge.exercises.map(
      (exercise) => new Exercise(exercise),
    );

    monoisotopic.exercises = monoisotopic.exercises.map(
      (exercise) =>
        new Exercise(exercise, {
          comparator: (answer, correct) => {
            return Math.abs(answer - correct) <= 0.0003;
          },
        }),
    );

    determineMF.exercises = determineMF.exercises.map(
      (exercise) =>
        new Exercise(exercise, {
          comparator: (answer, correct) => {
            let result;
            try {
              result =
                new MF(answer).getInfo().monoisotopicMass ===
                new MF(correct).getInfo().monoisotopicMass;
            } catch (error) {
              result = false;
            }
            return result;
          },
        }),
    );

    isotopicDistribution.exercises = isotopicDistribution.exercises.map(
      (exercise) =>
        new Exercise(exercise, {
          comparator: (answer, correct) => {
            let result;
            try {
              result =
                new MF(answer).getInfo().monoisotopicMass ===
                new MF(correct).getInfo().monoisotopicMass;
            } catch (error) {
              result = false;
            }
            return result;
          },
        }),
    );

    this.exercises = {
      charge,
      monoisotopic,
      determineMF,
      isotopicDistribution,
    };
    return this;
  };

  this.build = () => {
    const exercises = this.exercises;

    return { ...this.state, exercises };
  };
}
