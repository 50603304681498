import classNames from 'classnames';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function Item({ name, path, exact, onClick }) {
  const location = useLocation();
  return (
    <Link
      to={path}
      className={classNames(
        exact
          ? location.pathname === path && 'active'
          : location.pathname.startsWith(path) && 'active',
        'w-full nav-link',
      )}
      onClick={onClick}
    >
      <span className="font-normal">{name}</span>
    </Link>
  );
}
