import { UPDATE_CHART_SETTINGS } from '../../actions/actions';

function updateChartSettings(state, chart) {
  const { profile, centroid, fwhm, peaks } = chart;
  return { ...state, chart: { profile, centroid, fwhm, peaks } };
}

export function reducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_CHART_SETTINGS:
      return updateChartSettings(state, payload);

    default:
      return state;
  }
}
