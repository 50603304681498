import React, { createContext, useReducer, useCallback, useMemo } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

import StateBuilder from './StateBuilder';
import rootReducer from './rootReducer';

const initialSettings = {
  chart: {
    fwhm: 0.01,
    centroid: true,
    profile: false,
    peaks: false,
  },
};

const initialExercises = {
  charge: {
    exercises: [],
  },
  monoisotopic: {
    exercises: [],
  },
  isotopicDistribution: {
    exercises: [],
  },
  determineMF: {
    exercises: [],
  },
};

const initialState = {
  settings: initialSettings,
  exercises: initialExercises,
};

const store = createContext(initialState);
const { Provider } = store;

function StoreProvider({ children }) {
  const [persistedStore, setPersistedStore] = useLocalStorage(
    'storage',
    initialState,
  );

  const BuildState = useMemo(
    () => new StateBuilder(persistedStore).initExercises().build(),
    [persistedStore],
  );

  const reducer = useCallback((state = {}, action = {}) => {
    const newStore = rootReducer(state, action);
    setPersistedStore(newStore);
    return newStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-shadow
  const [state, dispatch] = useReducer(reducer, BuildState);

  return (
    <Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  );
}

export { store, StoreProvider };
