/* eslint-disable import/no-named-default */
import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import './assets/app.css';
import './assets/main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NewsPage } from './component/News';
import Page from './component/Page';
import ErrorBoundary from './component/utils/ErrorBoundary';
import Loading from './component/utils/Loading';
import NotFound from './component/utils/NotFound';
import { StoreProvider as Provider } from './context/store';

const Nucleotides = lazy(() =>
  import('./component/BioProducts/Nucleotides/Nucleotides'),
);

const Peptides = lazy(() =>
  import('./component/BioProducts/Peptides/Peptides'),
);

const MolecularFormula = lazy(() =>
  import('./component/MolecularFormula/MolecularFormula'),
);

const ExerciseSeries = lazy(() =>
  import('./component/Exercises/ExerciseSeries/Page'),
);

const Statistics = lazy(() => import('./component/Exercises/Statistics/Page'));

const Polymer = lazy(() => import('./component/BioProducts/Polymer/Polymer'));

const MFFinder = lazy(() => import('./component/MFFinder/MFFinder'));

const Atoms = lazy(() => import('./component/References/Atoms'));

const Groups = lazy(() => import('./component/References/Groups'));

const WebServices = lazy(() => import('./component/WebServices/WebServices'));

const Intro = lazy(() => import('./component/Exercises/Intro'));

const CiteUs = lazy(() => import('./component/Citation/CiteUs'));

const Contact = lazy(() => import('./component/Citation/Contact'));

function AppContent() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Provider>
          <Switch>
            <Route exact path="/">
              <Page Component={MolecularFormula} />
            </Route>

            <Route exact path="/mf-finder">
              <Page Component={MFFinder} />
            </Route>
            <Route exact path="/ws">
              <Page Component={WebServices} />
            </Route>
            <Route exact path="/atoms">
              <Page Component={Atoms} />
            </Route>
            <Route exact path="/groups">
              <Page Component={Groups} />
            </Route>
            <Route exact path="/peptides">
              <Page Component={Peptides} />
            </Route>
            <Route exact path="/nucleotides">
              <Page Component={Nucleotides} />
            </Route>
            <Route exact path="/polymer">
              <Page Component={Polymer} />
            </Route>

            <Route
              exact
              path="/exercise/:type"
              render={(props) => <Page {...props} Component={Intro} />}
            />

            <Route
              exact
              path="/exercise/:type/exercises"
              render={(props) => <Page {...props} Component={ExerciseSeries} />}
            />

            <Route
              exact
              path="/exercise/:type/statistics"
              render={(props) => <Page {...props} Component={Statistics} />}
            />

            <Route exact path="/contact">
              <Page Component={Contact} />
            </Route>
            <Route exact path="/news">
              <Page Component={NewsPage} />
            </Route>
            <Route exact path="/citeus">
              <Page Component={CiteUs} />
            </Route>
            <Route path="*">
              <Page Component={NotFound} />
            </Route>
          </Switch>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppContent;
