import { reducer as exercisesReducer } from '../reducers/exercises/reducer';
import { reducer as settingsReducer } from '../reducers/settings/reducer';

function combineReducers(reducers) {
  return (state = {}, action = {}) => {
    const newState = {};
    for (let key in reducers) {
      newState[key] = reducers[key](state[key], action);
    }

    return newState;
  };
}

const rootReducer = combineReducers({
  settings: settingsReducer,
  exercises: exercisesReducer,
});

export default rootReducer;
