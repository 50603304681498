import { MDBIcon } from 'mdbreact';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SubNavMenu({
  title,
  isOpen,
  isNew,
  onClick,
  children,
}) {
  return (
    <div className="w-full overflow-hidden">
      <Link className="nav-link" onClick={onClick} to={'#'}>
        <span className="mr-2 font-normal">
          {isNew && (
            <span className="p-1 mr-2 font-bold bg-gray-100 text-gray-900 rounded-lg">
              NEW
            </span>
          )}

          {title}
        </span>
        {isOpen ? <MDBIcon icon="angle-up" /> : <MDBIcon icon="angle-down" />}
      </Link>
      {isOpen && <div className="ml-6 w-full">{children}</div>}
    </div>
  );
}
