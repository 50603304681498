import React from 'react';

function Page({ Component, ...otherProps }) {
  return (
    <div className="m-auto">
      <Component {...otherProps} />
    </div>
  );
}

export default Page;
