import { MF } from 'mass-tools';
import ReactGA from 'react-ga';

import {
  CREATE_NEW_EXERCISES,
  CREATE_ANSWER_ATTEMPT,
} from '../../actions/actions';

function getNewExercises(newExercises, existingExercises, nbExercises) {
  let existingObject = {};
  existingExercises.map((entry) => (existingObject[entry.id] = entry));
  let newObject = {};
  newExercises.map((entry) => (newObject[entry.id] = entry));
  let result = [];
  for (let key in newObject) {
    if (!existingObject[key]) {
      result.push(newObject[key]);
    }
  }
  return result.slice(0, nbExercises);
}

function createMFs(options = {}) {
  let {
    nbMFs = 10,
    ionizations = ['H+'],
    mfs = ['Ala', 'Cys', 'Gly', 'Ser', 'Thr', 'Cl', 'Br', 'Me', 'Tos', 'Ph'],
    minMFs = 2,
    maxMFs = 4,
    baseMF = '',
    maxMW = 1e6,
    ignoreUnsaturation = false,
  } = options;

  const exercises = [];
  for (let i = 0; i < nbMFs; i++) {
    let mf = baseMF;
    let nbMF = Math.floor(Math.random() * (maxMFs - minMFs + 1)) + minMFs;
    for (let j = 0; j < nbMF; j++) {
      mf += mfs[Math.floor(Math.random() * mfs.length)];
    }
    let info = new MF(mf).getInfo();
    if (info.mass > maxMW) {
      i--;
      continue;
    }
    if (!ignoreUnsaturation) {
      if (info.unsaturation !== Math.floor(info.unsaturation)) {
        mf += 'H';
        info = new MF(mf).getInfo();
      }
      if (info.unsaturation < 0) {
        mf += `C${-info.unsaturation}`;
        info = new MF(mf).getInfo();
      }
    }

    const ionization =
      ionizations[Math.floor(Math.random() * ionizations.length)];
    const msInfo = new MF(mf + ionization).getInfo();
    exercises.push({
      msmf: msInfo.mf,
      mf: info.mf,
      ionization,
      charge: msInfo.charge,
      em: info.monoisotopicMass,
      msem: msInfo.monoisotopicMass,
      unsaturation: info.unsaturation,
    });
  }
  return exercises;
}

function createChargeExercises(existingExercises, nbExercises) {
  let mfs = createMFs({
    nbMFs: nbExercises + 10,
    baseMF: 'C4H8',
    ionizations: ['+', '++', '+++', '++++', '+++++', '++++++'],
  });
  let exercises = mfs.map((mf) => ({
    answer: mf.charge,
    id: mf.msmf,
    mf: mf.msmf,
    created: Date.now(),
    attempts: [],
  }));
  return getNewExercises(exercises, existingExercises, nbExercises);
}

function createMonoisotopicExercises(existingExercises, nbExercises) {
  let mfs = createMFs({
    nbMFs: nbExercises + 10,
    baseMF: 'C4H8',
    ionizations: ['+', 'H+', 'Na+', '(H+)2', '(H+)3', 'K+'],
  });
  let exercises = mfs
    .map((mf) => ({
      answer: mf.em,
      id: mf.msmf,
      mf: mf.msmf,
      ionization: mf.ionization,
      created: Date.now(),
      attempts: [],
    }))
    .sort((ex1, ex2) => ex1.ionization.length - ex2.ionization.length);
  return getNewExercises(exercises, existingExercises, nbExercises);
}

// eslint-disable-next-line no-unused-vars
function createIsotopicDistributionExercises(existingExercises, nbExercises) {
  let mfs = createMFs({
    nbMFs: nbExercises + 10,
    ionizations: ['+'],
    ignoreUnsaturation: true,
    mfs: ['C', 'C', 'C', 'Cl', 'Br', 'S'],
    minMFs: 1,
    maxMFs: 4,
  });
  let exercises = mfs.map((mf) => ({
    answer: mf.mf,
    id: mf.msmf,
    mf: mf.msmf,
    ionization: mf.ionization,
    created: Date.now(),
    attempts: [],
  }));
  return getNewExercises(exercises, existingExercises, nbExercises);
}

function createMFExercises(existingExercises, nbExercises) {
  let mfs = createMFs({
    nbMFs: nbExercises + 50,
    baseMF: 'C4H8',
    ionizations: ['+', 'H+', 'Na+', '(H+)2', '(H+)3'],
    mfs: ['Ala', 'Cys', 'Phe', 'Ph', 'O', 'Cl', 'Br', 'Et', 'Me'],
    maxMW: 200,
    minMFs: 1,
    maxMFs: 3,
  });

  const exercises = [];
  for (let mf of mfs) {
    const precision = [20, 50, 100][Math.floor(Math.random() * 3)];
    exercises.push({
      answer: mf.mf,
      id: mf.msmf,
      mf: mf.msmf,
      precision,
      shift: (mf.msem * precision * (Math.random() - 0.5) * 1.5) / 1e6,
      ionization: mf.ionization,
      created: Date.now(),
      attempts: [],
    });
  }
  return getNewExercises(exercises, existingExercises, nbExercises);
}

function createExercises(state, payload = {}) {
  const { kind = 'charge', nbExercises = 10, existingExercises = [] } = payload;

  ReactGA.event({
    category: 'Exercises',
    action: 'Create 10 exercises',
    label: kind,
    nonInteraction: false,
    value: existingExercises.length + 10,
  });

  switch (kind) {
    case 'charge': {
      const { charge } = state;
      const newExercises = createChargeExercises(
        existingExercises,
        nbExercises,
      );

      const exercises = [...charge.exercises, ...newExercises];
      return { ...state, charge: { ...charge, exercises } };
    }

    case 'monoisotopic': {
      const { monoisotopic } = state;
      const newExercises = createMonoisotopicExercises(
        existingExercises,
        nbExercises,
      );

      const exercises = [...monoisotopic.exercises, ...newExercises];

      return { ...state, monoisotopic: { ...monoisotopic, exercises } };
    }

    case 'determineMF': {
      const { determineMF } = state;
      const newExercises = createMFExercises(existingExercises, nbExercises);

      const exercises = [...determineMF.exercises, ...newExercises];

      return { ...state, determineMF: { ...determineMF, exercises } };
    }

    case 'isotopicDistribution': {
      const { isotopicDistribution } = state;
      const newExercises = createIsotopicDistributionExercises(
        existingExercises,
        nbExercises,
      );

      const exercises = [...isotopicDistribution.exercises, ...newExercises];

      return {
        ...state,
        isotopicDistribution: { ...isotopicDistribution, exercises },
      };
    }
    default:
      throw new Error('Unknown kind');
  }
}

function createAttempt(state, payload) {
  const { key } = payload;
  const { exerciseId, date, answer } = payload;
  switch (key) {
    case 'charge': {
      const {
        charge: { exercises },
      } = state;
      exercises[exerciseId].attempts.unshift({
        date,
        answer,
      });

      ReactGA.event({
        category: 'Exercises',
        action: exercises[exerciseId].checkAnswer(answer)
          ? 'Succeeded attempt'
          : 'Failed attempt',
        label: key,
        nonInteraction: false,
        value: exercises[exerciseId].attempts.length,
      });

      return { ...state, charge: { ...state.charge, exercises } };
    }

    case 'monoisotopic': {
      const {
        monoisotopic: { exercises },
      } = state;
      exercises[exerciseId].attempts.unshift({
        date,
        answer,
      });

      ReactGA.event({
        category: 'Exercises',
        action: exercises[exerciseId].checkAnswer(answer)
          ? 'Succeeded attempt'
          : 'Failed attempt',
        label: key,
        nonInteraction: false,
        value: exercises[exerciseId].attempts.length,
      });

      return { ...state, monoisotopic: { ...state.monoisotopic, exercises } };
    }

    case 'determineMF': {
      const {
        determineMF: { exercises },
      } = state;
      exercises[exerciseId].attempts.unshift({
        date,
        answer,
      });

      ReactGA.event({
        category: 'Exercises',
        action: exercises[exerciseId].checkAnswer(answer)
          ? 'Succeeded attempt'
          : 'Failed attempt',
        label: key,
        nonInteraction: false,
        value: exercises[exerciseId].attempts.length,
      });

      return { ...state, determineMF: { ...state.determineMF, exercises } };
    }

    case 'isotopicDistribution': {
      const {
        isotopicDistribution: { exercises },
      } = state;
      exercises[exerciseId].attempts.unshift({
        date,
        answer,
      });

      ReactGA.event({
        category: 'Exercises',
        action: exercises[exerciseId].checkAnswer(answer)
          ? 'Succeeded attempt'
          : 'Failed attempt',
        label: key,
        nonInteraction: false,
        value: exercises[exerciseId].attempts.length,
      });

      return {
        ...state,
        isotopicDistribution: { ...state.isotopicDistribution, exercises },
      };
    }

    default:
      return state;
  }
}

export function reducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case CREATE_NEW_EXERCISES:
      return createExercises(state, payload);
    case CREATE_ANSWER_ATTEMPT:
      return createAttempt(state, payload);

    default:
      return state;
  }
}
