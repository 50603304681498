import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const style = {
  boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
  background: '#ffffffb3',
};

let website = {
  date: 1583217035124,
  title: 'New website !',
  content: (
    <>
      We are proud to present our new website that is finally mobile friendly !
    </>
  ),
};

let polyCalc = {
  date: 1585809046391,
  title: 'Our new tool:',
  content: (
    <>
      <p>
        MS PolyCalc, the ultimate polymer distribution analyzer. To test with
        Google Chrome ! Desport JS, Frache G, Patiny L Rapid Commun. Mass
        Spectrom. 2019.
      </p>
      <p>
        <b>DOI:</b>{' '}
        <a target="blank" href="https://doi.org/10.1002/rcm.8652">
          https://doi.org/10.1002/rcm.8652
        </a>
      </p>
    </>
  ),
};

let exercises = {
  date: 1588401149924,
  title: 'We love teaching !',
  content: (
    <>
      <p>
        Today we started a new section on ChemCalc website: <b>the exercises</b>
        . The goal is to add various kinds of exercises dealing with molecular
        formulae, monoisotopic mass and isotopic distribution.
      </p>
      <div>
        <b>What is unique ?</b>
        <ul className="pl-4 list-disc">
          <li>
            Exercises are generated randomly. Every student has a different
            series!
          </li>
          <li>No login required</li>
          <li>Results are stored locally in your browser</li>
          <li>Detailed statistics about the progression are available</li>
          <li>
            A suggestion? A comment? <a href="#/contact">Contact us !</a>
          </li>
        </ul>
      </div>
    </>
  ),
};

// eslint-disable-next-line no-unused-vars
let newsList = [website, polyCalc, exercises];

export function NewsCard() {
  let news = newsList;
  let random = (Math.random() * 1000) >> 0;
  let oneNews = news[random % news.length];

  let date = new Date(oneNews.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <Card className="pl-4 pr-4 pb-4 pt-2 mx-auto mt-6" style={style}>
      <div className="flex flex-wrap">
        <div className="font-medium ml-auto italic">{date}</div>
        <div className="text-xl w-full font-bold">{oneNews.title}</div>
        <div className="pt-2 w-full">{oneNews.content}</div>
        <div className="pt-2 w-full">
          <Link to="/news" className="hover:no-underline font-medium">
            See all news
          </Link>
        </div>
      </div>
    </Card>
  );
}

export function NewsPage() {
  newsList = newsList.sort((a, b) => b.date - a.date);

  return (
    <div className="w-11/12 md:w-3/4 m-auto">
      {newsList.map((news) => (
        <Card
          key={news}
          className="pl-4 pr-4 pb-4 pt-2 mx-auto mt-6"
          style={style}
        >
          <div className="flex flex-wrap">
            <div className="font-medium ml-auto italic">
              {new Date(news.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </div>
            <div className="text-xl w-full font-bold">{news.title}</div>
            <div className="pt-2 w-full">{news.content}</div>
          </div>
        </Card>
      ))}
    </div>
  );
}
