/* eslint-disable no-unused-vars */
import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

export default class ErrorBoundary extends React.Component {
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(errorMessage) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    const ga = ReactGA.ga();

    ga('send', 'exception', {
      exDescription: `${error.stack}`,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center w-full">
          <div
            className=" absolute text-center w-11/12"
            style={{
              marginTop: '25vh',
            }}
          >
            <h2
              className="atom-heading"
              style={{
                fontSize: '80px',
              }}
            >
              Oups ... there was an error.
            </h2>
            <Link
              as="button"
              to="/"
              type="submit"
              className="mt-6 border-white px-4 py-2 border-2 rounded-lg text-white font-medium uppercase text-lg hover:bg-black outline-none outline-none focus:ring hover:no-underline"
            >
              Go to home page
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
