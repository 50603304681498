import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';

import Item from './Item';
import SubNavMenu from './SubNavMenu';

function useOpenSubMenuId(pathname) {
  let id = '';
  if (
    pathname.match('/peptides') ||
    pathname.match('/nucleotides') ||
    pathname.match('/polymer')
  ) {
    id = 'BIO';
  } else if (pathname.startsWith('/exercise')) {
    id = 'EXO';
  } else if (pathname.match('/atoms') || pathname.match('/groups')) {
    id = 'REF';
  }

  return useState(id);
}
function NavMenu({ toggleMenuVisibility }) {
  const location = useLocation();
  const [openSubMenuId, setOpenSubMenuId] = useOpenSubMenuId(location.pathname);

  return (
    <div>
      <i
        className="fas fa-bars fa-2x text-white block w-6 h-6 float-right mx-4 mt-4 mb-16 cursor-pointer"
        onClick={() => toggleMenuVisibility(true)}
      />

      <Nav variant="pills" className="w-full font-normal">
        <Item
          exact
          path="/"
          name="Molecular Formula"
          onClick={toggleMenuVisibility}
        />
        <Item
          exact
          path="/mf-finder"
          name="Moniosotopic mass"
          onClick={toggleMenuVisibility}
        />
        <SubNavMenu
          title="Bio / Polymers"
          isOpen={openSubMenuId.match('BIO')}
          onClick={() =>
            setOpenSubMenuId(openSubMenuId.match('BIO') ? '' : 'BIO')
          }
        >
          <Item
            exact
            path="/peptides"
            name="Peptides"
            onClick={toggleMenuVisibility}
          />
          <Item
            exact
            path="/nucleotides"
            name="Nucleotides"
            onClick={toggleMenuVisibility}
          />
          <Item
            exact
            path="/polymer"
            name="Polymer"
            onClick={toggleMenuVisibility}
          />
        </SubNavMenu>

        <SubNavMenu
          title="Exercises"
          isOpen={openSubMenuId.match('EXO')}
          isNew
          onClick={() =>
            setOpenSubMenuId(openSubMenuId.match('EXO') ? '' : 'EXO')
          }
        >
          <Item
            path="/exercise/charge"
            name="Charge"
            onClick={toggleMenuVisibility}
          />
          <Item
            path="/exercise/monoisotopic"
            name="Monoisotopic mass"
            onClick={toggleMenuVisibility}
          />
          <Item
            path="/exercise/isotopicDistribution"
            name="Isotopic distribution"
            onClick={toggleMenuVisibility}
          />
          <Item
            path="/exercise/determineMF"
            name="MOlecular formula"
            onClick={toggleMenuVisibility}
          />
        </SubNavMenu>

        <Item path="/ws" name="Web Services" onClick={toggleMenuVisibility} />
        <SubNavMenu
          title="Reference Data"
          isOpen={openSubMenuId.match('REF')}
          onClick={() =>
            setOpenSubMenuId(openSubMenuId.match('REF') ? '' : 'REF')
          }
        >
          <Item
            exact
            path="/atoms"
            name="List of Atoms"
            onClick={toggleMenuVisibility}
          />
          <Item
            exact
            path="/groups"
            name="List of Groups"
            onClick={toggleMenuVisibility}
          />
        </SubNavMenu>
        <Item exact path="/news" name="News" onClick={toggleMenuVisibility} />
        <Item
          exact
          path="/citeus"
          name="Cite Us"
          onClick={toggleMenuVisibility}
        />
      </Nav>

      <div className="fixed bottom-0 flex items-center">
        <Link to="/contact" onClick={toggleMenuVisibility} className="nav-link">
          <span className="font-normal"> Contact Us</span>
        </Link>

        <Nav.Link
          target="_blank"
          href="https://twitter.com/cheminformatics"
          eventKey="link"
          className="p-0"
        >
          <i className="fab fa-2x fa-twitter text-white" />
        </Nav.Link>

        <Nav.Link
          target="_blank"
          href="https://www.facebook.com/cheminformatic"
          eventKey="link-2"
        >
          <i className="fab fa-2x fa-facebook-square text-white" />
        </Nav.Link>
      </div>
    </div>
  );
}

export default NavMenu;
