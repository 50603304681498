export default class Exercise {
  constructor(exercise, options = {}) {
    for (let key in exercise) {
      this[key] = exercise[key];
    }
    this.comparator =
      options.comparator ||
      ((answer, correct) => {
        return correct === answer;
      });
  }

  checkAnswer(answer) {
    return this.comparator(this.answer, answer);
  }

  isFirstAttemptCorrect() {
    return this.isAttemptCorrect(0);
  }

  isSecondAttemptCorrect() {
    return this.isAttemptCorrect(1);
  }

  isThirdAttemptCorrect() {
    return this.isAttemptCorrect(2);
  }

  isAnyAttemptCorrect() {
    for (let attempt of this.attempts) {
      if (this.comparator(attempt.answer, this.answer)) return true;
    }
    return false;
  }

  isLastAttemptCorrect() {
    if (this.attempts.length === 0) return false;
    return this.comparator(this.answer, this.attempts[0].answer);
  }

  isAttemptCorrect(rank) {
    if (this.attempts.length === 0 || rank >= this.attempts.length) {
      return false;
    }
    return this.comparator(
      this.answer,
      this.attempts[this.attempts.length - rank - 1].answer,
    );
  }

  getLastAttempt() {
    if (this.attempts.length === 0) return;
    return this.attempts[0];
  }

  getFirstAttempt() {
    if (this.attempts.length === 0) return;
    return this.attempts[this.attempts.length - 1];
  }

  isAttempted() {
    return this.attempts.length > 0;
  }
}
