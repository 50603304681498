import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

import './assets/app.css';
import './assets/main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AppContent from './AppContent';
import NavMenu from './component/NavMenu/NavMenu';
import useMedia from './hooks/useMedia';

ReactGA.initialize('UA-78922811-1', { testMode: true });

function App() {
  const isMobile = useMedia(['(max-width: 500px)'], [true], false);
  const [menuVisibility, setMenuVisibility] = useState(!isMobile);

  const toggleMenuVisibility = (forced) => {
    if (isMobile === true) setMenuVisibility(false);
    else if (forced === true) setMenuVisibility(false);
  };

  useEffect(() => {
    setMenuVisibility(!isMobile);
  }, [isMobile]);

  return (
    <div className="w-full relative pb-5">
      <BrowserRouter>
        <div
          id="side_menu"
          className="fixed z-10"
          style={{
            marginLeft: menuVisibility === true ? '0px' : '-400px',
            minHeight: '100vh',
          }}
        >
          <NavMenu toggleMenuVisibility={toggleMenuVisibility} />
        </div>

        <i
          className="fas fa-bars fa-2x text-white block w-6 h-6 cursor-pointer inline m-4"
          onClick={() => setMenuVisibility(true)}
        />

        <Image
          src="/images/chemcalc.png"
          alt="ChemCalc logo"
          className="relative h-10 mx-auto mt-3 float-right md:h-12 md:mx-4 md:mt-16"
        />
        <div
          id="content"
          className={classNames('mt-4', menuVisibility && 'lg:ml-64')}
        >
          <AppContent />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
