// eslint-disable no-console
import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes() && !module.hot) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    console.log('cra called onUpdate callback');
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          console.log('service worker activated, prompt for reload');
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });

      console.log('send skip waiting');
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },

  onSuccess: () => {
    console.log('cra called onSuccess callback');
  },
});
