import React from 'react';

export default function NotFound() {
  return (
    <div className="flex justify-center w-full">
      <h2
        className="atom-heading absolute"
        style={{
          marginTop: '25vh',
          fontSize: '100px',
        }}
      >
        Not Found
      </h2>
    </div>
  );
}
